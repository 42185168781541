<template>
  <div>
    <!-- address section start -->
    <section class="contact-page policy-content section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="text-center mb-5 mt-3 font-weight-bold">
              eParisheva Privacy-Policy
            </h2>
            <ul class="eb-useing-gumtree">
              <h3>EFFECTIVE DATE: APRIL 15, 2021</h3>
              <li>
                Welcome to eParisheva. Our Privacy Policy explains how we
                collect, use, disclose, and protect information that applies to
                our Service, and your choices about the collection and use of
                your information. This policy sets out how eParisheva collects
                and uses the information that we collect about you when you use
                the eParisheva services. This policy also explains the choices
                that you can make about the way that we use your information.
              </li>

              <h3>Definitions</h3>

              <li>
                <i class="flaticon-checked"></i> Personal Data: Personal Data
                means data about a living individual who can be identified from
                those data.
              </li>
              <li>
                <i class="flaticon-checked"></i> Usage Data: Usage Data is data
                collected automatically either generated by the use of the
                Service or from the Service infrastructure itself.
              </li>
              <li>
                <i class="flaticon-checked"></i> Cookies: Cookies are small
                pieces stored on your device (computer or mobile device).
              </li>

              <h2 class="mt-4">Information we collect and its use</h2>
              <h3>We collect the following types of information about you:</h3>
              <h4>Information you provide us directly</h4>
              <li>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you. Personally identifiable information may
                include Email Address, Phone Number, Business Name, First and
                Last Name, Cookies and Usage Data.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3 class="mt-4">Usage Data</h3>
              <li>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access the Service by or
                through a mobile device ("Usage Data").
              </li>
              <li>
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </li>
              <li>
                When you access the Service with a mobile device, this Usage
                Data may include information such as the type of mobile device
                you use, your mobile device unique ID, the IP address of your
                mobile device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Cookies Information</h3>
              <li>
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </li>
              <li>
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
              </li>
              <li>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Use of Data</h3>
              <li>eParisheva uses the collected data for various purposes:</li>

              <li>
                <i class="flaticon-checked"></i> To provide and maintain our
                Service.
              </li>
              <li>
                <i class="flaticon-checked"></i> To notify you about changes to
                our Service.
              </li>
              <li>
                <i class="flaticon-checked"></i> To allow you to participate in
                interactive features of our Service when you choose to do so.
              </li>
              <li>
                <i class="flaticon-checked"></i> To provide customer support.
              </li>
              <li>
                <i class="flaticon-checked"></i> To gather analysis or valuable
                information so that we can improve our Service.
              </li>
              <li>
                <i class="flaticon-checked"></i> To monitor the usage of our
                Service.
              </li>
              <li>
                <i class="flaticon-checked"></i> To detect, prevent and address
                technical issues.
              </li>
              <li>
                <i class="flaticon-checked"></i>To provide you with news,
                special offers and general information about other goods,
                services and events which we offer that are similar to those
                that you have already purchased or enquired about unless you
                have opted not to receive such information.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Retention of Data</h3>
              <li>
                eParisheva will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes and enforce our legal agreements and policies.
              </li>

              <li>
                eParisheva will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of our Service, or we
                are legally obligated to retain this data for longer time
                periods.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Transfer of Data</h3>
              <li>
                Your information, including Personal Data, may be transferred to
                — and maintained on — computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </li>
              <li>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </li>
              <li>
                eParisheva will take all the steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organization or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Who you may choose to share your User Content with</h3>

              <li>
                If eParisheva is involved in a merger, acquisition or asset
                sale, your Personal Data may be transferred. We will provide
                notice before your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Disclosure for Law Enforcement</h3>
              <li>
                Under certain circumstances, eParisheva may be required to
                disclose your Content if required to do so by law or in response
                to valid requests by public authorities (e.g. a court or a
                government agency).
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Legal Requirements</h3>
              <li>
                eParisheva may disclose your Personal Data in the good faith
                belief that such action is necessary to:
              </li>

              <li>
                <i class="flaticon-checked"></i>To comply with a legal
                obligation.
              </li>
              <li>
                <i class="flaticon-checked"></i>To protect and defend the rights
                or property of eParisheva.
              </li>
              <li>
                <i class="flaticon-checked"></i>To prevent or investigate
                possible wrongdoing in connection with the Service.
              </li>
              <li>
                <i class="flaticon-checked"></i>To protect the personal safety
                of users of the Service or the public.
              </li>
              <li>
                <i class="flaticon-checked"></i>To protect against legal
                liability.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Security of Data</h3>
              <li>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </li>

              <li>
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Service Providers</h3>
              <li>
                We may employ third party companies and individuals to
                facilitate our Service ("Service Providers"), provide the
                Service on our behalf, perform Service-related services or
                assist us in analyzing how our Service is used.
              </li>
              <li>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Analytics</h3>
              <li>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </li>

              <li>
                <i class="flaticon-checked"></i>Google Analytics: Google
                Analytics is a web analytics service offered by Google that
                tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network.
              </li>

              <li>
                For more information on the privacy practices of Google, please
                visit the Google Privacy
                <a href="https://policies.google.com/privacy?hl=en-US"
                  >Terms web page</a
                >
              </li>

              <h3>Children's Privacy</h3>

              <li>
                eParisheva does not knowingly collect or solicit personal
                information from children under the age of 18 and the Service
                and its content are not directed at children under the age of
                18. In the event that we learn that we have collected personal
                information from a child under age 18 without verification of
                parental consent, we will delete that information as quickly as
                possible. If you believe that we might have any information from
                or about a child under 18. Please contact us

                <a href="mailto:support@trigital.in">support@trigital.in</a>
              </li>

              <h3>How long we keep your User Content.</h3>
              <li>
                Following termination or deactivation of your User account,
                eParisheva may retain your profile information and User Content
                for a commercially reasonable time, and for as long as we have a
                valid purpose to do so. In particular, eParisheva may retain
                your information for the purpose of comply with its legal and
                audit obligations, and for backup and archival purposes.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <h3>Disclosure Text for Inclusion in Privacy Policy</h3>
              <li>
                We may disclose to third party services certain personally
                identifiable information listed below:
              </li>

              <li>
                <i class="flaticon-checked"></i>information you provide us such
                as name, email, mobile phone number.
              </li>
              <li>
                <i class="flaticon-checked"></i>information we collect as you
                access and use our service, including device information,
                location and network carrier.
              </li>
              <li>
                This information is shared with third party service providers so
                that we can:
              </li>
              <li>
                <i class="flaticon-checked"></i>personalize the app for you.
              </li>
              <li>
                <i class="flaticon-checked"></i>perform behavioral analytics.
              </li>

              <h3>Any Updates to this Privacy Policy</h3>
              <li>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                "effective date" at the top of this Privacy Policy. Please
                review this Privacy Policy periodically for any changes. Changes
                to this Privacy Policy are effective when they are posted on
                this page.
              </li>

              <h3>Contact Us</h3>
              <li>
                For any questions about this Privacy Policy, please contact us
                at
                <a href="mailto:support@trigital.in">support@trigital.in</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- Section ends -->
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.policy-content .eb-useing-gumtree {
  position: relative;
}
.policy-content h3 {
  color: #533333;
  font-weight: 600;
}
.policy-content .eb-device-terms li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  margin-bottom: 10px;
  display: list-item;
  list-style: circle;
  margin-left: 1.4%;
}
.policy-content .eb-useing-gumtree li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  display: list-item;
  list-style: none;
}
.policy-content .eb-useing-gumtree li i {
  position: absolute;
  top: 3px;
  left: 0;
  color: #828282;
  font-size: 14px;
  background-color: #d7eef8;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}
.flaticon-checked:before {
  content: "✓";
}
</style>
