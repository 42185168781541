var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "contact-page policy-content section-b-space"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('h2', {
    staticClass: "text-center mb-5 mt-3 font-weight-bold"
  }, [_vm._v(" eParisheva Privacy-Policy ")]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("EFFECTIVE DATE: APRIL 15, 2021")]), _c('li', [_vm._v(" Welcome to eParisheva. Our Privacy Policy explains how we collect, use, disclose, and protect information that applies to our Service, and your choices about the collection and use of your information. This policy sets out how eParisheva collects and uses the information that we collect about you when you use the eParisheva services. This policy also explains the choices that you can make about the way that we use your information. ")]), _c('h3', [_vm._v("Definitions")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" Personal Data: Personal Data means data about a living individual who can be identified from those data. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" Usage Data: Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" Cookies: Cookies are small pieces stored on your device (computer or mobile device). ")]), _c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Information we collect and its use")]), _c('h3', [_vm._v("We collect the following types of information about you:")]), _c('h4', [_vm._v("Information you provide us directly")]), _c('li', [_vm._v(" While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include Email Address, Phone Number, Business Name, First and Last Name, Cookies and Usage Data. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', {
    staticClass: "mt-4"
  }, [_vm._v("Usage Data")]), _c('li', [_vm._v(" We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device (\"Usage Data\"). ")]), _c('li', [_vm._v(" This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. ")]), _c('li', [_vm._v(" When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Cookies Information")]), _c('li', [_vm._v(" We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. ")]), _c('li', [_vm._v(" Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service. ")]), _c('li', [_vm._v(" You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Use of Data")]), _c('li', [_vm._v("eParisheva uses the collected data for various purposes:")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" To provide and maintain our Service. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" To notify you about changes to our Service. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" To allow you to participate in interactive features of our Service when you choose to do so. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" To provide customer support. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" To gather analysis or valuable information so that we can improve our Service. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" To monitor the usage of our Service. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v(" To detect, prevent and address technical issues. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Retention of Data")]), _c('li', [_vm._v(" eParisheva will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies. ")]), _c('li', [_vm._v(" eParisheva will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Transfer of Data")]), _c('li', [_vm._v(" Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. ")]), _c('li', [_vm._v(" Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. ")]), _c('li', [_vm._v(" eParisheva will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Who you may choose to share your User Content with")]), _c('li', [_vm._v(" If eParisheva is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Disclosure for Law Enforcement")]), _c('li', [_vm._v(" Under certain circumstances, eParisheva may be required to disclose your Content if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Legal Requirements")]), _c('li', [_vm._v(" eParisheva may disclose your Personal Data in the good faith belief that such action is necessary to: ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("To comply with a legal obligation. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("To protect and defend the rights or property of eParisheva. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("To prevent or investigate possible wrongdoing in connection with the Service. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("To protect the personal safety of users of the Service or the public. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("To protect against legal liability. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Security of Data")]), _c('li', [_vm._v(" The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security. ")]), _c('li', [_vm._v(" You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Service Providers")]), _c('li', [_vm._v(" We may employ third party companies and individuals to facilitate our Service (\"Service Providers\"), provide the Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used. ")]), _c('li', [_vm._v(" These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Analytics")]), _c('li', [_vm._v(" We may use third-party Service Providers to monitor and analyze the use of our Service. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("Google Analytics: Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. ")]), _c('li', [_vm._v(" For more information on the privacy practices of Google, please visit the Google Privacy "), _c('a', {
    attrs: {
      "href": "https://policies.google.com/privacy?hl=en-US"
    }
  }, [_vm._v("Terms web page")])]), _c('h3', [_vm._v("Children's Privacy")]), _c('li', [_vm._v(" eParisheva does not knowingly collect or solicit personal information from children under the age of 18 and the Service and its content are not directed at children under the age of 18. In the event that we learn that we have collected personal information from a child under age 18 without verification of parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 18. Please contact us "), _c('a', {
    attrs: {
      "href": "mailto:support@trigital.in"
    }
  }, [_vm._v("support@trigital.in")])]), _c('h3', [_vm._v("How long we keep your User Content.")]), _c('li', [_vm._v(" Following termination or deactivation of your User account, eParisheva may retain your profile information and User Content for a commercially reasonable time, and for as long as we have a valid purpose to do so. In particular, eParisheva may retain your information for the purpose of comply with its legal and audit obligations, and for backup and archival purposes. ")])]), _c('ul', {
    staticClass: "eb-useing-gumtree"
  }, [_c('h3', [_vm._v("Disclosure Text for Inclusion in Privacy Policy")]), _c('li', [_vm._v(" We may disclose to third party services certain personally identifiable information listed below: ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("information you provide us such as name, email, mobile phone number. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("information we collect as you access and use our service, including device information, location and network carrier. ")]), _c('li', [_vm._v(" This information is shared with third party service providers so that we can: ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("personalize the app for you. ")]), _c('li', [_c('i', {
    staticClass: "flaticon-checked"
  }), _vm._v("perform behavioral analytics. ")]), _c('h3', [_vm._v("Any Updates to this Privacy Policy")]), _c('li', [_vm._v(" We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the \"effective date\" at the top of this Privacy Policy. Please review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. ")]), _c('h3', [_vm._v("Contact Us")]), _c('li', [_vm._v(" For any questions about this Privacy Policy, please contact us at "), _c('a', {
    attrs: {
      "href": "mailto:support@trigital.in"
    }
  }, [_vm._v("support@trigital.in")])])])])])])])]);
}]

export { render, staticRenderFns }